import React, { Component } from 'react'
import Link from '../utils/link'

import { Brand } from './icons'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__col'>
            <Link to='/' title='The Ryan Dixon Group' className='footer__logo'>
              <Brand />
            </Link>
            <p>© Copyright {(new Date().getFullYear())} - The Ryan Dixon Group. <Link to='https://foxhat.melbourne'>Brand by WFM </Link>|<Link to='https://atollon.com.au'>Site by Atollon</Link></p>
          </div>
          <div className='footer__col'>
            <h4>Navigation</h4>
            <ul>
              <li>
                <Link to='/'>Home</Link><br />
                <Link to='/find/'>Find</Link><br />
                <Link to='/us/'>Us</Link><br />
                <Link to='https://www.linkedin.com/in/ryan-dixon-70368725/'>LinkedIn</Link>
              </li>
        
            </ul>
          </div>
          <div className='footer__col'>
            <h4>Contact Details</h4>
            <ul>
              <li>
                411 Remuera Rd, Remuera<br />
                Available 7 days. Please call either<br />
                Ryan Dixon <Link to='tel:0276323285'>027 632 3285</Link><br />
                Alex Smith <Link to='tel:0277725552'>027 772 5552</Link><br />
                Olivia Field <Link to='tel:0220942675'>022 094 2675</Link>
              </li>
            </ul>
          </div>
          <div className='footer__col'>
            <h4>Legal</h4>
            <ul>
              <li>
                Ray White Remuera.<br />
                Megan Jaffe Real Estate Ltd <br />
                Licensed (REAA 2008)
              </li>
            </ul>
            <p>© Copyright {(new Date().getFullYear())} - The Ryan Dixon Group. <Link to='https://atollon.com.au'>Site Credit</Link></p>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
