import React from 'react'

export const Logo = ({ color = '#FFFFFF' }) => (
  <svg width="40px" height="28px" viewBox="0 0 40 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <polygon points="11.112 0.327637 0 0.327637 0 27.3171 11.112 27.3171"></polygon>
      <polygon points="39.9997 0.327637 28.8877 0.327637 28.8877 27.3171 39.9997 27.3171"></polygon>
      <path d="M27.8158,20.7 C27.8158,24.3523 24.8012,27.3117 21.0808,27.3117 C17.3603,27.3117 14.3457,24.3523 14.3457,20.7 C14.3457,17.0477 17.3603,14.0882 21.0808,14.0882 C24.8012,14.0882 27.8158,17.0477 27.8158,20.7 Z"></path>
      <path d="M25.4955,6.55803 C25.4955,10.1781 22.5027,13.1161 18.8151,13.1161 C15.1275,13.1161 12.1348,10.1781 12.1348,6.55803 C12.1348,2.93795 15.122,0 18.8151,0 C22.5082,0 25.4955,2.93795 25.4955,6.55803 Z"></path>
    </g>
  </svg>
)

export const Rdg = () => (
  <svg width="265" height="16" viewBox="0 0 265 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M69.4465 15.7384V9.32648L63.9583 0.261562H67.8435L69.6984 3.63942C69.9884 4.17002 70.622 5.49276 71.1868 6.57637C71.7059 5.60486 72.2707 4.43158 72.7211 3.617L74.576 0.254089H78.3467L72.8585 9.31901V15.731H69.4465V15.7384Z" fill="#FFFFFF"/>
    <path d="M81.6289 9.72254H85.5371L84.499 6.32225C84.2242 5.50768 83.7967 4.40166 83.5525 3.29563H83.5296C83.2166 4.55859 82.9189 5.35075 82.606 6.32225L81.6366 9.72254H81.6289ZM87.6591 15.7384L86.4378 12.1139H80.6824L79.484 15.7384H75.8735L81.8274 0.261536H85.3463L91.2849 15.7309H87.6515L87.6591 15.7384Z" fill="#FFFFFF"/>
    <path d="M92.6816 15.7384V0.261536H96.6127L100.361 7.39839C100.994 8.61651 101.964 10.3578 102.505 11.7776H102.551C102.46 10.298 102.414 8.22043 102.414 7.31618V0.261536H105.62V15.7384H101.689L97.7577 8.35495C97.2157 7.36102 96.2921 5.70198 95.796 4.3344H95.7502C95.8418 6.03828 95.8876 8.18307 95.8876 8.62398V15.7384H92.6816Z" fill="#FFFFFF"/>
    <path d="M116.688 13.1303H118.879C120.55 13.1303 121.772 12.6221 122.535 11.673C123.26 10.7688 123.504 9.48339 123.504 8.00371C123.504 6.52403 123.367 5.44042 122.626 4.3344C121.886 3.22837 120.504 2.87714 118.879 2.87714H116.688V13.1303ZM113.276 15.7384V0.261536H119.1C124.184 0.261536 127.046 3.333 127.046 7.99624C127.046 12.6595 124.023 15.7309 119.1 15.7309H113.276V15.7384Z" fill="#FFFFFF"/>
    <path d="M132.679 0.261536H129.267V15.7309H132.679V0.261536Z" fill="#FFFFFF"/>
    <path d="M143.35 7.73468L148.678 15.7384H144.64L142.656 12.5997C142.228 11.9122 141.709 11.0079 141.282 10.2755C140.9 10.9631 140.381 11.8449 139.885 12.6221L137.923 15.7384H133.931L139.305 7.71226L134.298 0.261536H138.229L139.946 2.98176C140.374 3.66929 140.938 4.50628 141.366 5.23865C141.77 4.5287 142.312 3.66929 142.74 2.98176L144.434 0.261536H148.343L143.35 7.73468Z" fill="#FFFFFF"/>
    <path d="M159.846 11.9794C160.594 11.0079 160.884 9.52823 160.884 8.00371C160.884 6.47919 160.525 4.88741 159.846 4.00558C159.121 3.05649 157.968 2.63799 156.594 2.63799C155.151 2.63799 153.976 3.03407 153.205 3.96074C152.503 4.79773 152.098 6.45677 152.098 8.00371C152.098 9.68517 152.457 11.0303 153.09 11.9196C153.861 12.9808 155.144 13.3769 156.503 13.3769C157.968 13.3769 159.144 12.9136 159.846 11.9869M148.556 8.01118C148.556 3.32552 151.426 0.00744629 156.503 0.00744629C161.579 0.00744629 164.426 3.34794 164.426 8.01118C164.426 12.6744 161.51 16.0149 156.503 16.0149C151.495 16.0149 148.556 13.1602 148.556 8.01118Z" fill="#FFFFFF"/>
    <path d="M166.609 15.7384V0.261536H170.54L174.288 7.39839C174.921 8.61651 175.891 10.3578 176.432 11.7776H176.478C176.387 10.298 176.341 8.22043 176.341 7.31618V0.261536H179.547V15.7384H175.616L171.685 8.35495C171.143 7.36102 170.219 5.70198 169.723 4.3344H169.677C169.769 6.03828 169.815 8.18307 169.815 8.62398V15.7384H166.609Z" fill="#FFFFFF"/>
    <path d="M229.796 11.9794C230.544 11.0079 230.834 9.52823 230.834 8.00371C230.834 6.47919 230.475 4.88741 229.796 4.00558C229.071 3.05649 227.918 2.63799 226.544 2.63799C225.102 2.63799 223.926 3.03407 223.155 3.96074C222.453 4.79773 222.048 6.45677 222.048 8.00371C222.048 9.68517 222.407 11.0303 223.041 11.9196C223.812 12.9808 225.094 13.3769 226.453 13.3769C227.918 13.3769 229.094 12.9136 229.796 11.9869M218.507 8.01118C218.507 3.32552 221.377 0.00744629 226.453 0.00744629C231.529 0.00744629 234.376 3.34794 234.376 8.01118C234.376 12.6744 231.46 16.0149 226.453 16.0149C221.445 16.0149 218.507 13.1602 218.507 8.01118Z" fill="#FFFFFF"/>
    <path d="M236.406 10.5894V0.261536H239.818V10.3876C239.818 12.3979 241.169 13.2798 243.024 13.2798C244.879 13.2798 246.253 12.3979 246.253 10.3876V0.261536H249.665V10.5894C249.665 14.1242 247.36 16.0074 243.024 16.0074C238.689 16.0074 236.414 14.1317 236.414 10.5894" fill="#FFFFFF"/>
    <path d="M255.947 7.49554H258.275C260.061 7.49554 261.573 7.38344 261.573 5.14897C261.573 2.9145 260.061 2.86966 258.298 2.86966H255.947V7.48807V7.49554ZM252.535 15.7384V0.261536H258.359C261.336 0.261536 265 0.702451 265 5.14897C265 9.59549 261.413 10.0962 258.359 10.0962H255.947V15.7309H252.535V15.7384Z" fill="#FFFFFF"/>
    <path d="M202.194 15.7086H199.866L199.424 14.1243C198.179 15.2751 196.393 15.9328 194.5 15.9328C190.073 15.9328 186.447 13.2649 186.447 7.99628C186.447 2.72772 190.21 0.0448608 194.767 0.0448608C198.416 0.0448608 201.576 1.83094 201.965 5.38068L198.416 5.66466C198.286 3.52735 196.63 2.46616 194.767 2.46616C192.18 2.46616 190.004 4.29708 190.004 7.98134C190.004 11.6656 192.05 13.4965 194.508 13.4965C196.37 13.4965 198.157 12.525 198.653 11.1574V10.014H195.775V7.58526H202.194V15.7011V15.7086Z" fill="#FFFFFF"/>
    <path d="M207.988 6.96494H210.759C212.034 6.96494 212.331 6.92011 212.797 6.6436C213.362 6.32225 213.751 5.62725 213.751 4.9472C213.751 4.34187 213.453 3.76644 212.988 3.4152C212.499 3.06396 212.202 3.01913 210.66 3.01913H207.98V6.96494H207.988ZM207.988 15.7309H204.553V0.261536H211.736C213.843 0.261536 214.629 0.538042 215.606 1.30778C216.659 2.12235 217.323 3.51235 217.323 4.90236C217.323 6.01586 216.881 7.12935 216.095 7.92151C215.705 8.31758 214.873 8.91544 214.873 8.91544L217.888 15.7309H214.026L211.522 9.61044H207.973V15.7309H207.988Z" fill="#FFFFFF"/>
    <path d="M5.06078 15.7385V2.99676H0V0.179382H13.5565V2.99676H8.48806V15.7385H5.06078Z" fill="#FFFFFF"/>
    <path d="M15.0068 15.7385V0.179382H18.4341V6.965H24.5864V0.179382H28.0214V15.7385H24.5864V9.78985H18.4341V15.7385H15.0068Z" fill="#FFFFFF"/>
    <path d="M30.9983 15.7385V0.179382H42.1656V2.86971H34.4256V7.03226H41.3259V9.72259H34.4256V13.0481H42.1656V15.7385H30.9983Z" fill="#FFFFFF"/>
    <path d="M54.0121 6.96494H56.7829C58.0576 6.96494 58.3553 6.92011 58.8209 6.6436C59.3858 6.32225 59.7751 5.62725 59.7751 4.9472C59.7751 4.34187 59.4774 3.76644 59.0118 3.4152C58.5233 3.06396 58.2256 3.01913 56.6837 3.01913H54.0044V6.96494H54.0121ZM54.0121 15.7309H50.5771V0.261536H57.7599C59.8667 0.261536 60.6529 0.538042 61.6299 1.30778C62.6833 2.12235 63.3474 3.51235 63.3474 4.90236C63.3474 6.01586 62.9047 7.12935 62.1185 7.92151C61.7292 8.31758 60.8972 8.91544 60.8972 8.91544L63.9123 15.7309H60.0499L57.5462 9.61044H53.9968V15.7309H54.0121Z" fill="#FFFFFF"/>
  </svg>
)

export const Brand = ({ color = '#FFFFFF' }) => (
  <svg width="140px" height="64px" viewBox="0 0 140 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <path d="M20.6285,40.3013 L20.6285,33.158 L14.6284,23.0524 L18.8738,23.0524 L20.8954,26.8218 C21.2188,27.411 21.9062,28.8881 22.5208,30.0989 C23.0868,29.0173 23.7014,27.7097 24.2027,26.7976 L26.2243,23.0524 L30.3484,23.0524 L24.3483,33.158 L24.3483,40.3013 L20.6204,40.3013 L20.6285,40.3013 Z" id="Path"></path>
      <path d="M40.5372,40.3013 L39.2029,36.2575 L32.9036,36.2575 L31.5936,40.3013 L27.6475,40.3013 L34.1651,23.0524 L38.0142,23.0524 L44.5076,40.3013 L40.5291,40.3013 L40.5372,40.3013 Z M33.9225,33.1822 L38.1921,33.1822 L37.0843,29.8083 C36.7851,28.8962 36.3161,27.6612 36.0492,26.4344 L36.025,26.4344 C35.6773,27.8388 35.3619,28.7267 35.0142,29.8083 L33.9306,33.1822 L33.9225,33.1822 Z" id="Shape"></path>
      <path d="M46.0361,40.3013 L46.0361,23.0524 L50.33,23.0524 L54.4298,31.011 C55.1253,32.367 56.1846,34.3122 56.7749,35.8862 L56.8234,35.8862 C56.7264,34.2315 56.6778,31.923 56.6778,30.906 L56.6778,23.0443 L60.1873,23.0443 L60.1873,40.2933 L55.8935,40.2933 L51.5996,32.0603 C51.0093,30.9545 49.9985,29.1061 49.4486,27.5725 L49.4001,27.5725 C49.4971,29.4693 49.5456,31.8585 49.5456,32.3508 L49.5456,40.2852 L46.0442,40.2852 L46.0361,40.3013 Z" id="Path"></path>
      <path d="M67.5215,40.3013 L67.5215,23.0524 L73.8936,23.0524 C79.4489,23.0524 82.5865,26.4747 82.5865,31.6809 C82.5865,36.887 79.2791,40.3094 73.8936,40.3094 L67.5215,40.3094 L67.5215,40.3013 Z M71.2493,37.3956 L73.6429,37.3956 C75.4704,37.3956 76.8047,36.8305 77.6457,35.7732 C78.4381,34.7642 78.705,33.3356 78.705,31.6809 C78.705,30.0262 78.5594,28.8235 77.7427,27.5886 C76.926,26.3536 75.4219,25.9581 73.6429,25.9581 L71.2493,25.9581 L71.2493,37.3956 Z" id="Shape"></path>
      <polygon id="Path" points="88.74 23.0524 85.0122 23.0524 85.0122 40.3013 88.74 40.3013"></polygon>
      <path d="M100.417,31.3822 L106.239,40.3013 L101.824,40.3013 L99.6487,36.7983 C99.1797,36.0315 98.6136,35.0225 98.1446,34.2154 C97.7241,34.9822 97.1581,35.9669 96.6163,36.8305 L94.4653,40.3013 L90.0986,40.3013 L95.9775,31.358 L90.5191,23.0524 L94.813,23.0524 L96.6891,26.0873 C97.1581,26.8541 97.7726,27.7904 98.2416,28.5975 C98.6864,27.8065 99.2767,26.846 99.7457,26.0873 L101.598,23.0524 L105.867,23.0524 L100.409,31.3822 L100.417,31.3822 Z" id="Path"></path>
      <path d="M106.11,31.6809 C106.11,26.4586 109.247,22.7618 114.803,22.7618 C120.358,22.7618 123.471,26.4828 123.471,31.6809 C123.471,36.879 120.285,40.6 114.803,40.6 C109.32,40.6 106.11,37.4198 106.11,31.6809 Z M118.458,36.1203 C119.275,35.0387 119.59,33.384 119.59,31.6809 C119.59,29.9778 119.194,28.2102 118.458,27.2173 C117.665,26.16 116.412,25.6918 114.9,25.6918 C113.388,25.6918 112.037,26.1358 111.196,27.1689 C110.428,28.1052 109.983,29.9536 109.983,31.6809 C109.983,33.5535 110.379,35.0548 111.067,36.0396 C111.908,37.2261 113.315,37.67 114.795,37.67 C116.396,37.67 117.682,37.1535 118.45,36.1203" id="Shape"></path>
      <path d="M125.849,40.3013 L125.849,23.0524 L130.143,23.0524 L134.242,31.011 C134.938,32.367 135.997,34.3122 136.587,35.8862 L136.636,35.8862 C136.539,34.2315 136.49,31.923 136.49,30.906 L136.49,23.0443 L140,23.0443 L140,40.2933 L135.706,40.2933 L131.412,32.0603 C130.822,30.9545 129.811,29.1061 129.261,27.5725 L129.213,27.5725 C129.31,29.4693 129.358,31.8585 129.358,32.3508 L129.358,40.2852 L125.857,40.2852 L125.849,40.3013 Z" id="Path"></path>
      <path d="M3.75209,40.3013 L0,40.3013 L0,23.0524 L7.85999,23.0524 C10.1646,23.0524 11.0218,23.3591 12.0973,24.2147 C13.2455,25.1187 13.9733,26.6684 13.9733,28.2262 C13.9733,29.4693 13.4881,30.7123 12.631,31.5921 C12.2024,32.028 11.2967,32.706 11.2967,32.706 L14.596,40.3094 L10.3749,40.3094 L7.64166,33.4808 L3.75209,33.4808 L3.75209,40.3094 L3.75209,40.3013 Z M3.75209,30.5266 L6.7845,30.5266 C8.17536,30.5266 8.49882,30.4782 9.00826,30.1634 C9.62283,29.8002 10.0514,29.0253 10.0514,28.2747 C10.0514,27.6047 9.72795,26.959 9.21851,26.5716 C8.6848,26.1841 8.36135,26.1357 6.67129,26.1357 L3.74401,26.1357 L3.74401,30.5347 L3.75209,30.5266 Z" id="Shape"></path>
      <path d="M62.7021,54.6284 C62.7021,49.4061 65.8397,45.7094 71.395,45.7094 C76.9504,45.7094 80.0637,49.4303 80.0637,54.6284 C80.0637,59.8265 76.8776,63.5475 71.395,63.5475 C65.9125,63.5475 62.7021,60.3673 62.7021,54.6284 Z M75.0501,59.0678 C75.8668,57.9862 76.1822,56.3315 76.1822,54.6284 C76.1822,52.9253 75.786,51.1577 75.0501,50.1649 C74.2576,49.1075 73.0042,48.6393 71.4921,48.6393 C69.9152,48.6393 68.6295,49.0833 67.7885,50.1164 C67.0203,51.0527 66.5755,52.9011 66.5755,54.6284 C66.5755,56.501 66.9718,58.0024 67.6591,58.9871 C68.5001,60.1736 69.9071,60.6175 71.3869,60.6175 C72.9881,60.6175 74.2738,60.101 75.042,59.0678" id="Shape"></path>
      <path d="M82.2793,57.518 L82.2793,46.0079 L86.0071,46.0079 L86.0071,57.292 C86.0071,59.5359 87.4869,60.5206 89.5085,60.5206 C91.5302,60.5206 93.0423,59.5359 93.0423,57.292 L93.0423,46.0079 L96.7701,46.0079 L96.7701,57.518 C96.7701,61.465 94.2553,63.5555 89.5085,63.5555 C84.7618,63.5555 82.2793,61.4569 82.2793,57.518 Z" id="Path"></path>
      <path d="M99.9238,63.2569 L99.9238,46.0079 L106.296,46.0079 C109.555,46.0079 113.558,46.5003 113.558,51.4562 C113.558,56.4122 109.628,56.9772 106.296,56.9772 L103.652,56.9772 L103.652,63.2569 L99.9238,63.2569 L99.9238,63.2569 Z M103.652,54.0634 L106.191,54.0634 C108.14,54.0634 109.797,53.9423 109.797,51.4482 C109.797,48.9541 108.14,48.9137 106.215,48.9137 L103.652,48.9137 L103.652,54.0634 Z" id="Shape"></path>
      <path d="M44.8634,63.2247 L42.3162,63.2247 L41.831,61.457 C40.4725,62.7323 38.5156,63.4749 36.4536,63.4749 C31.6098,63.4749 27.6475,60.4965 27.6475,54.6204 C27.6475,48.7443 31.7554,45.7659 36.7366,45.7659 C40.7313,45.7659 44.1842,47.7595 44.6047,51.7146 L40.7232,52.0375 C40.5857,49.6483 38.7663,48.4698 36.7285,48.4698 C33.8983,48.4698 31.5209,50.512 31.5209,54.6123 C31.5209,58.7127 33.7608,60.7628 36.4455,60.7628 C38.4832,60.7628 40.4402,59.6813 40.9739,58.1557 L40.9739,56.8804 L37.8282,56.8804 L37.8282,54.1764 L44.8473,54.1764 L44.8473,63.2247 L44.8634,63.2247 Z" id="Path"></path>
      <path d="M51.2033,63.2569 L47.4512,63.2569 L47.4512,46.0079 L55.3112,46.0079 C57.6158,46.0079 58.4729,46.3147 59.5484,47.1702 C60.6967,48.0743 61.4245,49.624 61.4245,51.1818 C61.4245,52.4248 60.9393,53.6679 60.0821,54.5477 C59.6536,54.9835 58.7479,55.6615 58.7479,55.6615 L62.0471,63.265 L57.826,63.265 L55.0928,56.4364 L51.2114,56.4364 L51.2114,63.265 L51.2033,63.2569 Z M51.2033,53.4822 L54.2357,53.4822 C55.6265,53.4822 55.95,53.4338 56.4594,53.119 C57.074,52.7558 57.5026,51.9809 57.5026,51.2302 C57.5026,50.5603 57.1791,49.9146 56.6697,49.5271 C56.136,49.1397 55.8125,49.0913 54.1225,49.0913 L51.1952,49.0913 L51.1952,53.4903 L51.2033,53.4822 Z" id="Shape"></path>
      <polygon id="Path" points="19.6095 17.3458 19.6095 3.14791 14.0703 3.14791 14.0703 0 28.8927 0 28.8927 3.14791 23.3535 3.14791 23.3535 17.3458"></polygon>
      <polygon id="Path" points="30.4858 17.3458 30.4858 0 34.2298 0 34.2298 7.57113 40.9658 7.57113 40.9658 0 44.7098 0 44.7098 17.3458 40.9658 17.3458 40.9658 10.719 34.2298 10.719 34.2298 17.3458"></polygon>
      <polygon id="Path" points="47.9688 17.3458 47.9688 0 60.1873 0 60.1873 2.99455 51.7208 2.99455 51.7208 7.64378 59.2655 7.64378 59.2655 10.6383 51.7208 10.6383 51.7208 14.3513 60.1873 14.3513 60.1873 17.3458"></polygon>
      <path d="M119.525,45.8868 C121.053,45.8868 122.291,47.1218 122.291,48.6473 C122.291,50.1728 121.053,51.4078 119.525,51.3997396 C117.997,51.3997396 116.76,50.1648 116.767961,48.6392 C116.767961,47.1137 118.005,45.8868 119.533,45.8868 M119.533,50.8428 C120.746,50.8428 121.733,49.8581 121.733,48.6473 C121.733,47.4366 120.746,46.4519 119.533,46.4519 C118.32,46.4519 117.334,47.4366 117.334,48.6473 C117.334,49.8581 118.32,50.8428 119.533,50.8428 Z" id="Shape"></path>
      <path d="M118.312,47.1379 L118.328,47.1379 C118.716,47.1379 119.113,47.1379 119.501,47.1379 C119.687,47.1379 119.881,47.1622 120.059,47.2187 C120.18,47.259 120.285,47.3155 120.374,47.4124 C120.463,47.5092 120.512,47.6303 120.536,47.7675 C120.56,47.9128 120.552,48.0581 120.512,48.2034 C120.455,48.3971 120.334,48.5262 120.156,48.615 C120.107,48.6393 120.051,48.6554 120.002,48.6796 C120.067,48.6958 120.131,48.728 120.188,48.7684 C120.293,48.841 120.374,48.9379 120.431,49.0509 C120.512,49.1962 120.584,49.3576 120.665,49.5029 C120.746,49.6643 120.827,49.8338 120.908,49.9953 C120.908,49.9953 120.908,50.0114 120.924,50.0195 L120.156,50.0195 C120.156,50.0195 120.131,50.0195 120.123,50.0033 C120.002,49.7612 119.881,49.511 119.759,49.2688 C119.727,49.2123 119.695,49.1639 119.662,49.1074 C119.59,49.0105 119.493,48.946 119.371,48.9379 C119.266,48.9298 119.161,48.9298 119.056,48.9298 L119.056,50.0195 L118.312,50.0195 L118.312,47.1379 L118.312,47.1379 Z M119.056,48.4213 C119.185,48.4213 119.307,48.4213 119.436,48.4213 C119.485,48.4213 119.525,48.4132 119.573,48.3971 C119.687,48.3648 119.759,48.3002 119.792,48.1872 C119.816,48.0985 119.816,48.0016 119.792,47.9047 C119.768,47.8079 119.703,47.7352 119.606,47.7029 C119.557,47.6949 119.517,47.6787 119.468,47.6787 C119.331,47.6787 119.202,47.6787 119.064,47.6787 L119.064,48.4213 L119.056,48.4213 Z" id="Shape"></path>
    </g>
  </svg>
)

export const ThemeMode = ({ color = '#FFFFFF' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0C4.48338 0 0 4.48338 0 10C0 15.5166 4.48338 20 10 20C15.5166 20 20 15.5166 20 10C20 4.48338 15.5166 0 10 0ZM9.92958 1.19718V18.8028C5.11718 18.7562 1.19718 14.8355 1.19718 10.0001C1.19718 5.18772 5.11718 1.24406 9.92958 1.19741V1.19718Z" fill={color} />
  </svg>
)

export const Search = ({ color = '#FFFFFF' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.286 0.00112915C4.1656 0.00112915 0 4.16673 0 9.28713C0 14.4075 4.1656 18.5731 9.286 18.5731C11.5892 18.5731 13.6978 17.7278 15.322 16.3341L18.7806 19.7927C18.9103 19.9224 19.0884 20.0013 19.2853 20.0013C19.6798 20.0013 19.9993 19.681 19.9993 19.2873C19.9993 19.0896 19.9197 18.9107 19.79 18.7818L16.3306 15.3232C17.7251 13.699 18.5704 11.5904 18.5704 9.28721C18.5704 4.16681 14.4048 0.00120915 9.28436 0.00120915L9.286 0.00112915ZM9.286 1.42925C13.6336 1.42925 17.143 4.93865 17.143 9.28625C17.143 13.6338 13.6336 17.1432 9.286 17.1432C4.9384 17.1432 1.429 13.6338 1.429 9.28625C1.429 4.93865 4.9384 1.42925 9.286 1.42925Z" fill={color} />
  </svg>
)

export const Hamburger = ({ color = '#FFFFFF' }) => (
  <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0V2.06897H25.5172V0H0ZM0 8.96552V11.0345H25.5172V8.96552H0ZM0 17.931V20H25.5172V17.931H0Z" fill={color} />
  </svg>
)

export const Chevron = ({ color = '#D0CFC6' }) => (
  <svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.55179 7C7.45146 7 7.30097 6.94984 7.20065 6.89968L0.177896 0.880172C-0.0227538 0.679522 -0.0729164 0.378546 0.127734 0.177896C0.328384 -0.0227538 0.629359 -0.0729164 0.830009 0.127734L7.55179 5.84626L14.2233 0.127734C14.424 -0.0729164 14.7249 -0.0227538 14.9256 0.177896C15.1262 0.378546 15.0761 0.729684 14.8754 0.880172L7.85268 6.89968C7.80252 6.94984 7.65203 7 7.55171 7H7.55179Z" fill={color} />
  </svg>
)

export const Cross = ({ color = '#FFFFFF' }) => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.7075 1.55277L12.4136 10L21.7075 18.4472C22.0975 18.8018 22.0975 19.3779 21.7075 19.7341C21.3157 20.0886 20.6819 20.0886 20.292 19.7341L11 11.2851L1.70805 19.7341C1.31806 20.0886 0.684345 20.0886 0.292488 19.7341C-0.0974961 19.3779 -0.0974961 18.8018 0.292488 18.4472L9.58636 10L0.292488 1.55277C-0.0974961 1.19824 -0.0974961 0.622132 0.292488 0.265899C0.68435 -0.0886329 1.31806 -0.0886329 1.70805 0.265899L11 8.71487L20.292 0.265899C20.6819 -0.0886329 21.3157 -0.0886329 21.7075 0.265899C22.0975 0.622136 22.0975 1.19824 21.7075 1.55277Z" fill={color} />
  </svg>
)

export const Found = () => (
  <svg width="131" height="33" viewBox="0 0 131 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="49" height="33" fill="#11251E"/>
    <rect x="49" width="82" height="33" fill="#C6C2BE"/>
    <path d="M21.378 24V17.356H28.286V15.09L21.378 15.09V10.888L29.1 10.888V8.6L18.65 8.6L18.65 24H21.378ZM38.4355 24.264C43.2315 24.264 45.9815 21.008 45.9815 16.3C45.9815 11.658 43.2315 8.336 38.4355 8.336C33.5955 8.336 30.8895 11.636 30.8895 16.3C30.8895 21.426 33.6615 24.264 38.4355 24.264ZM38.4355 22.042C36.9395 22.042 35.6415 21.536 34.8495 20.48C34.2335 19.644 33.8155 18.17 33.8155 16.3C33.8155 14.562 34.2335 12.934 34.9375 12.032C35.6415 11.13 36.8735 10.558 38.5235 10.558C40.0415 10.558 41.2075 11.064 41.9555 12.098C42.6595 13.066 43.0555 14.54 43.0555 16.3C43.0555 18.016 42.7035 19.49 41.9555 20.502C41.2295 21.536 40.0415 22.042 38.4355 22.042Z" fill="#D0CFC6"/>
    <path d="M57.766 24.264C61.858 24.264 64.036 22.394 64.036 18.896V8.6L61.286 8.6V18.786C61.286 20.964 59.702 21.976 57.766 21.976C55.808 21.976 54.268 20.964 54.268 18.786V8.6H51.518V18.896C51.518 22.394 53.674 24.264 57.766 24.264ZM70.5227 24V16.146C70.5227 15.706 70.4787 13.616 70.4127 12.032H70.4347C70.9627 13.308 71.8867 15.002 72.3487 15.794L76.9907 24H80.1367V8.6H77.5187V16.41C77.5187 17.202 77.5627 19.248 77.6287 20.634H77.6067C77.0347 19.314 76.1987 17.774 75.5827 16.652L71.0067 8.6H67.9047V24H70.5227ZM89.6159 24C94.3239 24 97.1839 21.118 97.1839 16.3C97.1839 11.658 94.4559 8.6 89.6159 8.6L84.1379 8.6V24H89.6159ZM86.8879 10.822H89.3959C91.1339 10.822 92.5419 11.262 93.2899 12.362C94.0379 13.462 94.2579 14.584 94.2579 16.3C94.2579 17.994 93.9719 19.27 93.2459 20.26C92.4979 21.25 91.1999 21.778 89.3959 21.778H86.8879V10.822ZM102.037 24V21.03H99.1771V24H102.037Z" fill="#11251E"/>
  </svg>
)
