import React from 'react'
import PropTypes from 'prop-types'

import layoutQuery from "../hooks/use-layout-query"

import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'
import LoadingScreen from '../components/loading-screen'

const Layout = ({ children, location, pageContext }) => {
  const data = layoutQuery()

  const [dark, setDark] = React.useState(true)
  const [hide, setHide] = React.useState(false)

  React.useEffect(() => {
    const themeKeys = ['primaryColor', 'secondaryColor', 'black']
    if (dark) {
      document.documentElement.classList.remove('green');
    } else {
      document.documentElement.classList.add('green');
    }
    // document.documentElement.style.setProperty(`--${themeKeys[0]}`, dark ? '#1E1E1E' : '#0f251e');
    // document.documentElement.style.setProperty(`--${themeKeys[1]}`, dark ? '#0f251e' : '#1E1E1E');
    // document.documentElement.style.setProperty(`--${themeKeys[2]}`, dark ? '#000000' : '#0f251e');

    // document.documentElement.style.setProperty(`--${themeKeys[0]}`, dark ? '#0f251e' : '#1E1E1E');
    // document.documentElement.style.setProperty(`--${themeKeys[1]}`, dark ? '#1E1E1E' : '#0f251e');
    // document.documentElement.style.setProperty(`--${themeKeys[2]}`, dark ? '#0f251e' : '#000000');
  }, [dark])

  const toggleTheme = (event) => {
    event.preventDefault()
    setDark(!dark)
  }

  return (
    <>
      <Header toggleTheme={toggleTheme} location={location} data={data} pageContext={pageContext} />

      <main>{ children }</main>
      <Footer data={data} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
