import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    fragment WpSeo on WpPostTypeSEO {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        localFile {
          publicURL
        }
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      readingTime
      schema {
        articleType
        pageType
        raw
      }
      title
      twitterDescription
      twitterImage {
        localFile {
          publicURL
        }
      }
      twitterTitle
    }
    fragment ArticleCard on WpPost {
      id
      uri
      title
      content
      excerpt
      date(formatString: "ddd DD MMMM")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
              )
            }
          }
        }
      }
    }
    fragment TeamCard on WpTeam {
      id
      title
      uri
      info {
        id
        firstName
        lastName
        staffTypeId
        email
        position
        role
        thumbnail
      }
      phoneNumbers {
        number
        type
      }
      acf {
        cardImage {
          altText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 240
              )
            }
          }
        }
        profileImage {
          altText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 900
              )
            }
          }
        }
        tagline
        license
        bio
      }
    }
    fragment PropertyCard on WpProperty {
      id
      databaseId
      uri
      title
      propertyMeta {
        id
        address {
          unitNumber
          streetNumber
          street
          suburb {
            name
            postcode
          }
        }
        auctionDetails {
          auctioneer
          dateTime
          venue
        }
        addressVisibility
        heading
        commercialListingType
        available
        carports
        garages
        openSpaces
        bed
        bath
        landAreaValue
        landAreaUnits
        latitude
        longitude
        floorAreaValue
        floorAreaUnits
        displayPrice
        displayAddress
        latitude
        longitude
        description
        class
        type
        status
        searchPrice
      }

      images {
        url
        thumb_1024
      }
    }
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
}

export default useLayoutQuery
