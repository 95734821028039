import React, { Component } from 'react'
import Link from '../utils/link'

import { Logo, ThemeMode, Search, Rdg } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,

    scrolled: false,
    headerShow: true,
    sectorsSubNav: false,
    servicesSubNav: false,

    direction: 0,
    prevDirection: 0,
    prevScroll: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  toggleScrolled = () => {
  }

  onScroll = (e) => {
    let { direction, prevDirection, prevScroll, headerShow, scrolled } = this.state

    const curScroll = window.scrollY || document.scrollTop
    scrolled = curScroll > 1

    // Make sure they scroll more than 5px
    if(Math.abs(prevScroll - curScroll) <= 5) return;

    if (curScroll > prevScroll) {
      //scrolled up
      direction = 2;
    }
    else if (window.scrollY < prevScroll) {
      //scrolled down
      direction = 1;
    }

    if (direction !== prevDirection) {
      if (direction === 2 && curScroll > 120) {
        headerShow = false
      }
      else if (direction === 1) {
        headerShow = true
      }
    }

    // Bottom of page
    if (window.innerHeight + curScroll + 10 >= document.body.scrollHeight) {
      headerShow = true
    }

    prevScroll = curScroll;

    requestAnimationFrame(() => {
      this.setState({
        scrolled, headerShow,  direction, prevDirection, prevScroll
      })
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled, headerShow,  } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--inverse'
    if (!headerShow) headerClass += ' header--hide'
    if (offCanvas) headerClass += ' header--off-canvas-active'

    return (
      <>
        <header className={headerClass}>
          <div className='header__wrapper'>
            <div className='header__inner'>
              <Link to='/' title='The Ryan Dixon Group' className='header__logo' {...props}>
                <Logo />
                <div className='header__brand'>
                  <Rdg />
                </div>
              </Link>
              <nav className='header__nav'>
                <ul>
                  {/*<li>*/}
                  {/*  <button type='button' onClick={this.props.toggleTheme}>*/}
                  {/*    <ThemeMode />*/}
                  {/*  </button>*/}
                  {/*</li>*/}
                  <li>
                    <Link to='/find/'>
                      FIND
                    </Link>
                  </li>
                  <li>
                    <Link to='/found/'>
                      FOUND
                    </Link>
                  </li>
                  <li>
                    <Link to='/us/'>
                      US
                    </Link>
                  </li>
                  <li>
                    <Link to='/media/'>
                      VIEWS
                    </Link>
                  </li>
                  <li>
                    <Link to='/find/'>
                      <Search />
                    </Link>
                  </li>
                  <li>
                    <button type='button' onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                      <span className='lines'></span>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className='header__padding' />
        </header>
        <Link className='appraisal-button' to='/appraisal/'>
        REQUEST A CURRENT MARKET ASSESSMENT
        </Link>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <button type='button' onClick={this._toggleOffCanvas} className='header__hamburger active'>
              <span className='lines'></span>
            </button>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/' {...props}>Home</Link></li>
                <li><Link to='/find/' {...props}>Find</Link></li>
                <li><Link to='/found/' {...props}>Found</Link></li>
                <li><Link to='/us/' {...props}>Us</Link></li>
                <li><Link to='/media/' {...props}>Views</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
